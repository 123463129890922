
export default class StringTypes{
    constructor(){
       this.items = [
        {
            id: 1,
            value: "alphanumeric",
            label: "enum.string_types.alphanumeric.title",
            example: "enum.string_types.alphanumeric.example"
        },
        {
            id: 2,
            value: "alphanumericspecial",
            label: "enum.string_types.alphanumericspecial.title",
            example: "enum.string_types.alphanumericspecial.example"
        },
        {
            id: 3,
            value: "numeric",
            label: "enum.string_types.numeric.title",
            example: "enum.string_types.numeric.example"
        },
        {
            id: 4,
            value: "alphabetic",
            label: "enum.string_types.alphabetic.title",
            example: "enum.string_types.alphabetic.example"
        }
       ] 
    }
}